import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import {
  Chip, Container, Grid,
} from '@material-ui/core';
import Img from 'gatsby-image';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import SEO from '../components/seo';
import { IconRight } from '../components/featureCard';
import DlaczegoWarto from '../components/dlaczegoWarto';
import StartupsIcon from '../../assets/svg/startupy.svg';

import { kebabCase } from '../utils/slugs';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
        query posts {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
          filter: {frontmatter: {postType: {ne: "tag"}}}
        ) {
          edges {
            node {
              frontmatter {
                date(formatString: "DD-MM-YYYY")
                slug
                title
                author
                metaTitle
                desc
                lead
                tags
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                featuredImageAlt
              }
            }
          }
        }
      }
    `);

  return (
    <SubLayout
      title={(
        <>
          Software House
          {' '}
          <span>Blog</span>
        </>
              )}
      subtitle="Technologie, startupy i branża IT"
      img="/img/biznes-kontakt.jpg"
    >
      <SEO title="Blog - iqcode Software House w Warszawie" description="Blog technologiczny iqcode. Tutaj dzielimy się doświadczeniem przy realizacji projektów programistycznych." />
      <Container>
        <Grid spacing={2} container>
          <Grid className="blog-feed" item xs={12} sm={12} md={7}>
            {data.allMarkdownRemark.edges.map((post) => (
              <div key={post.node.frontmatter.slug}>
                <h2><Link to={post.node.frontmatter.slug}>{post.node.frontmatter.title}</Link></h2>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <div>
                    <img
                      alt={post.node.frontmatter.author}
                      src="/img/pkuffel.jpg"
                      style={{
                        width: '50px',
                        borderRadius: '100px',
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <address
                      className="author"
                      style={{
                        fontStyle: 'normal',
                        marginBottom: '8px',
                      }}
                    >
                      {post.node.frontmatter.author}
                    </address>
                    {post.node.frontmatter.date}
                    {' '}
                    <span className="post-tags-separator">|</span>
                    {' '}
                    <div className="post-tags">
                      {post.node.frontmatter.tags.map((tag) => (
                        <>
                          <Chip
                            component={Link}
                            to={`/blog/tag/${kebabCase(tag)}/`}
                            label={tag}
                            size="small"
                            style={{
                              marginBottom: '5px',
                              marginLeft: '5px',
                              verticalAlign: '0%',
                            }}
                          />
                          {' '}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <Img
                  style={{
                    margin: '15px 0px 30px 0px',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 15px #00000045',
                  }}
                  fluid={post.node.frontmatter.featuredImage.childImageSharp.fluid}
                  alt={post.node.frontmatter.featuredImageAlt}
                />
                <p>{post.node.frontmatter.lead}</p>
                <p>
                  <Link to={post.node.frontmatter.slug}>
                    Czytaj dalej
                    {' '}
                    <IconRight />
                  </Link>
                </p>
              </div>
            ))}
          </Grid>
          <Grid className="blog-side" item xs={12} sm={12} md={5}>
            <div className="proj-contact">
              <StartupsIcon width="50px" />
              <h5>Omówmy Twój projekt IT!</h5>
              <Link to="/kontakt/">
                Kontakt
                {' '}
                <IconRight />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Porozmawiajmy o Twoim
            {' '}
            <span>Projekcie</span>
            !
          </h3>
          <h4>
            Wycenę
            {' '}
            <i>fixed price</i>
            {' '}
            prześlemy w ciągu 72h
          </h4>
          <Link className="btn" to="/kontakt/">Kontakt</Link>
        </div>
      </div>
      <DlaczegoWarto />
      <AllServices />

    </SubLayout>
  );
};

export default BlogPage;
