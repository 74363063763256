import React from 'react';
import FeatureCardsSection from './featureCardsSection';
import KompleksowaUsluga from '../../assets/svg/iqcode-technologie-stack.svg';
import CzasRealizacji from '../../assets/svg/metodyki-zwinne-agile.svg';
import Gwarancja from '../../assets/svg/gwarancje-wsparcie-sla.svg';

const DlaczegoWarto = () => (
  <FeatureCardsSection
    title={(
      <>
        Dlaczego
        {' '}
        <span>warto</span>
      </>
              )}
    subtitle="Realizować projekt w iqcode"
    cards={[
      {
        title: (
          <>
            Nowoczesne
            <br />
            Technologie
          </>
        ),
        icon: <KompleksowaUsluga />,
        link: 'Nasze technologie',
        to: '/technologie/',
        desc:
                        'PHP i jQuery? To nie u nas. Stosujemy nowoczesne rozwiązania pozbawione długu technologicznego',
      },
      {
        title: (
          <>
            Metodyki
            <br />
            Zwinne
          </>
        ),
        icon: <CzasRealizacji />,
        link: 'Omówmy współpracę',
        to: '/kontakt/',
        desc:
                        'Projekty realizujemy w sposób iteracyjny z zaangażowaniem klienta',
      },
      {
        title: (
          <>
            Gwarancje i
            <br />
            Umowy SLA
          </>
        ),
        icon: <Gwarancja />,
        link: 'DevOps w iqcode',
        to: '/devops/',
        desc:
                        'Udzielamy pisemnych gwarancji na wykonane produkty cyfrowe z rygorystycznymi warunkami SLA',
      },
    ]}
  />
);

export default DlaczegoWarto;
